import React from 'react'
import { type PlaceSuggestion } from '../../types/types';
import Pin from '../../components/icons/Pin';

interface Props  {
  suggestion: PlaceSuggestion
  onSelect: (mapboxId: string) => {}
}
const Suggestion = ({ suggestion, onSelect}: Props) => {
  return (
    <li
      className='flex flex-col py-2 px-4 cursor-pointer'  
      onClick={() => onSelect(suggestion.mapbox_id)}>
      <div className='flex'>
        <span className="mr-1"><Pin /></span>
        <p className="font-montserrat">{suggestion.name}</p>
      </div>
      <p className="text-sm font-montserrat">{suggestion.place_formatted}</p>
    </li>
  )
}


export default Suggestion;